import React, {
  Fragment,
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import "./navbar.css";
import "./CurveNavbar.css";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/finallogo.png";
// import logo from "../../assets/images/logoc.png";
import { FaCar, FaRegHeart } from "react-icons/fa";
import { FiLogOut, FiMenu, FiLogIn } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { MdArrowForwardIos } from "react-icons/md";
import Drawer from "../../components/Drawer";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { LOG_OUT } from "../../constant";
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
import $ from "jquery";
  

const CurveNavbar = ({ setnavbarDimensions, auth, logOut }) => {
  const [mobileDrawer, setMobileDrawer] = useState(false);
  const navbarContainer = useRef(null);
  //
 const { feedback, isLoading } = useContext(FeedbackContext);
var curveNavBar = feedback.home_page;
const section2Buttons = [
  // {
  //   title: `${curveNavBar.homeTitle}`,
  //   to: "/",
  // },
  {
    title: `${curveNavBar.buyTitle}`,
    to: "/buynow",
  },
  {
    title: "Dashbord",
    to: "/dashbord",
  },
  // {
  //   title: `${curveNavBar.buyTitle}`,
  //   to: "/buynow",
  // },
  {
    title: `${curveNavBar.auctionTitle}`,
    to: "/auction",
  },
  {
    title: `${curveNavBar.interestTitle}`,
    to: "/showInterest",
  },
  {
    title: `${curveNavBar.myInterest}`,
    to: "/myInterest",
  }
  
];
const section2Buttons_mbl = [
  {
    title: `${curveNavBar.homeTitle}`,
    to: "/",
  },
  {
    title: `${curveNavBar.buyTitle}`,
    to: "/buynow",
  },
  {
    title: `${curveNavBar.auctionTitle}`,
    to: "/auction",
  },
  {
    title: `${curveNavBar.interestTitle}`,
    to: "/showInterest",
  },

  {
    title: `${curveNavBar.myInterestTitle}`,
    to: "/myInterest",
  },
  {
    title: `${curveNavBar.bidsTitle}`,
    to: "/winer",
  },
];

  let history = useHistory();
  useEffect(() => {
    function setnavbarDimensionsHandler() {
      if (navbarContainer.current.offsetWidth > 1023) {
        setMobileDrawer(false);
      }
      // // console.log
      //   "navbar height on change handler called",
      //   navbarContainer.current.offsetHeight
      // );

      setnavbarDimensions({
        width: navbarContainer.current.offsetWidth,
        height: navbarContainer.current.offsetHeight,
      });
    }

    window.addEventListener("resize", setnavbarDimensionsHandler);
    return () =>
      window.removeEventListener("resize", setnavbarDimensionsHandler);
  }, []);

  //
  const section1Buttons = [
    {
      title: `${curveNavBar.bidsTitle}`,
      to: "/winer",
      icon: <FaRegHeart className="section1Button-icon" />,
    },

    {
      title: auth.token
        ? `${curveNavBar.logoutLabel}`
        : `${curveNavBar.loginLabel}`,
      to: auth.token
        ? () => {
            logOut();
            // window.location.reload(true);
          }
        : "/login",
      icon: auth.token ? (
        <FiLogOut className="section1Button-icon" />
      ) : (
        <FiLogIn className="section1Button-icon" />
      ),
      isButton: auth.token ? true : false,
    },
  ];
  // -----------------mobile view --------------------- //
  const mobileviewLeftButtons = [];
  const mobileviewRightButtons = [
    {
      title: auth.token ? "logout" : "login",
      to: auth.token
        ? () => {
            logOut();
            // window.location.reload(true);
          }
        : () => {
            history.push("/login");
            // console.log"login buton click =============>>>>>>>>>>")
          },
      icon: <FiLogOut className="mobile-nav-button-icon" />,
    },
  ];
  const handleDrawer = () => {
    setMobileDrawer(!mobileDrawer);
  };

  return (
    <div id="curve-nav-bar-main-cont" ref={navbarContainer}>
      {/*--------------------------------------------------------------- desktop view -----------------------------*/}
      <div className="courve-nav-bar-container-desktop  ">
        <div className="courve-nav-bar-logo-container d-flex justify-content-start align-items-start">
          <img
            className="navlogo m-1"
            alt="Logo"
            src={logo}
            onClick={() => {
              // console.log"wwwwww")
              history.push("/");
            }}
          />
        </div>
        <div className="courve-nav-bar-body d-flex flex-column justify-content-center align-items-end">
          {/* first row */}
          <div className="courve-nav-bar-body-s courve-nav-bar-body-s1-main">
            <div className="courve-nav-bar-section-1-curve"></div>
            <div className="courve-nav-bar-body-s courve-nav-bar-body-s1 d-flex   justify-content-end align-items-center">
              <Nav className="courve-nav-bar-body-s1-child d-flex   justify-content-between">
                {React.Children.toArray(
                  section1Buttons.map((item, index) => (
                    <Fragment>
                      {item.isButton ? (
                        <button
                          key={"section1btn" + index}
                          onClick={item.to}
                          className="mobile-nav-menu-button-s1 d-flex  justify-content-center align-items-center"
                        >
                          {item.icon} {item.title}
                        </button>
                      ) : (
                        <NavLink
                          exact
                          key={"NavLink" + index}
                          to={item.to}
                          activeClassName="section1Button-active"
                          className="section1Button d-flex justify-content-center align-items-center"
                        >
                          {item.icon} {item.title}
                        </NavLink>
                      )}
                      {index === section1Buttons.length - 1 ? null : (
                        <div
                          key={"vertical" + index}
                          className="vertical-divider"
                        />
                      )}
                    </Fragment>
                  ))
                )}
              </Nav>
            </div>
          </div>
          {/* second row */}
          <div className="courve-nav-bar-body-s courve-nav-bar-body-s2 d-flex   justify-content-end align-items-center">
            <Nav className="courve-nav-bar-body-s1-child d-flex   justify-content-between">
              {React.Children.toArray(
                section2Buttons.map((item, index) => (
                  <NavLink
                    exact
                    to={item.to}
                    key={"NavLink2" + index}
                    activeClassName="selected"
                    className="unselected"
                  >
                    {item.title}
                  </NavLink>
                ))
              )}
            </Nav>
          </div>
        </div>
      </div>
      {/*--------------------------------------------------------------- mobile and tablet view -----------------------------*/}
      <div className="courve-nav-bar-container-mobile ">
        <div
          className="courve-nav-bar-logo-container d-flex justify-content-start align-items-start"
          style={{ flex: 1 }}
        >
          <img
            className="navlogo "
            alt="Logo"
            src={logo}
            onClick={() => {
              // console.log"wwwwww")
              history.push("/");
            }}
          />
        </div>

        {/* <a 

activeClassName="mobile-nav-button-active"
className="mobile-nav-button d-flex flex-column justify-content-center align-items-center"

href="https://sellanywheel.com/" target="_blank" rel="noopener noreferrer">
Sell Any Wheel
</a> */}
        <Nav className="courve-nav-bar-mobile-left-button ">
          {React.Children.toArray(
            mobileviewRightButtons.map((item, index) => (
              <button
                key={"mobileviewRightButtonsr" + index}
                onClick={item.to}
                // activeClassName="mobile-nav-button-active"
                // className="mobile-nav-button d-flex flex-column justify-content-center align-items-center"
                className="mobile-nav-menu-button d-flex flex-column justify-content-center align-items-center"
              >
                {item.icon} {item.title}
              </button>
            ))
          )}
          <button
            onClick={handleDrawer}
            className="mobile-nav-menu-button d-flex flex-column justify-content-center align-items-center"
          >
            <FiMenu className="mobile-nav-button-icon" />
            {mobileDrawer ? "close" : "menu"}
          </button>
        </Nav>

        <Drawer
          visible={mobileDrawer}
          handleDrawer={handleDrawer}
          body={
            <MobileDrawerBody
              handleDrawer={handleDrawer}
              section2Buttons_mbl={section2Buttons_mbl}
            />
          }
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  logOut: (data) => {
    dispatch({
      type: LOG_OUT,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CurveNavbar);

// drawer body
const MobileDrawerBody = (props) => {
  const { handleDrawer, section2Buttons_mbl } = props;
  return (
    <Fragment>
      <div className="courve-nav-bar-container-mobile d-flex align-items-center">
        <div
          className="courve-nav-bar-logo-container d-flex justify-content-start align-items-start"
          style={{ flex: 1 }}
        >
          <img className="navlogo m-1" alt="Logo" src={logo} />
        </div>
        <button
          onClick={handleDrawer}
          className="mobile-nav-menu-button d-flex flex-column justify-content-center align-items-center"
        >
          <AiOutlineClose className="mobile-nav-button-icon" />
          close
        </button>
      </div>
      <div className="mobile-dawer-body-cont">
        {React.Children.toArray(
          section2Buttons_mbl.map((item, index) => (
            <div
              className="mobile-dawer-body-links-cont"
              key={"section1btncNavLinkrw" + index}
            >
              <NavLink
                key={"section1btncNavLink" + index}
                onClick={handleDrawer}
                exact
                to={item.to}
                className="mobile-dawer-body-links"
              >
                <span key={"span" + index}>{item.title}</span>
                <MdArrowForwardIos key={"MdArrowForwardIos" + index} />
              </NavLink>
            </div>
          ))
        )}
      </div>
    </Fragment>
  );
};
